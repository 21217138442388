import React from 'react'
import "../Styles/events.css"
import "../Styles/Homepage.css"
import { Tab, Tabs, TabContent, Card } from 'react-bootstrap';
import { useState, useEffect } from 'react'
import axios from "axios"

import API_ROOT from '../apiConfig';
import fragment from "../Images/fragment.jpg"
import spa from "../Images/spa.jpg"

function Events() {
    const options = {

        year: 'numeric',
        month: 'short',
        day: 'numeric',
    };



    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const limit = 4;

    const [activeKey, setActiveKey] = useState('tab1');

    const handleTabSelect = (key) => {
        setActiveKey(key);
    };



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${API_ROOT}/api/post/homepost`);
                const removeHtmlTags = (html) => html.replace(/<[^>]*>/g, '');

                // Remove HTML tags from specific fields in the response data
                const cleanedData = {
                    ...response.data,

                    latest: response.data.latest.map(post => ({ ...post, post_content: removeHtmlTags(post.post_content) })),
                    popular: response.data.popular.map(post => ({ ...post, post_content: removeHtmlTags(post.post_content) })),

                };

                setData(cleanedData);

            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);
    return (
        <div>

            <div className='hr'></div>
            <div className='container container-max' >

          
                    <div className="row spaceincontent">
                        {/* Left Section - 9 columns */}
                        <div className="col-md-9">
                            <div className="d-flex justify-content-between">
                                <div className="data-logo col-md-6">
                                    <img src={fragment} alt="fragment" className="img-fluid" />
                                </div>

                                <div className="data-logo col-md-6">
                                    <img src={spa} alt="spa" className="img-fluid" />
                                </div>
                            </div>

                            <div className="paddings borderR">
                                <h3 className='fw-bold borderB py-1'>
                                    Capacity Europe - Supercharging Europe’s connectivity
                                </h3>

                                <div style={{ fontSize: "16px", padding: "10px" }}>

                                    <p className="p-text text-black" style={{ lineHeight: "2.2" }}>

                                        <ul>
                                            <li >
                                                The 2024 show brings together the entire connectivity and digital infrastructure ecosystem that help to
                                                secure, automate and remotely operate increasingly complex network infrastructure
                                            </li>
                                            <li>
                                                Taking place at the Intercontinental London from 15th October, the 3-day event is set to welcome over
                                                3,000 attendees
                                            </li>
                                            <li>
                                                Award-winning PR agency, Spa Communications, is spearheading this year’s media relations strategy.
                                            </li>
                                        </ul>

                                    </p>


                                    <p className="p-text">

                                        <a className="event-anchor" href='https://www.techoraco.com/'>techoraco</a>, the trusted provider of large-scale international networking events, has partnered with   <a className="event-anchor" href='https://spacomms.com/'>Spa
                                            Communications</a>, an award-winning PR, social media and content marketing agency for B2B technology,
                                        sustainability, and digital infrastructure businesses, to support its media relations strategy for its 2024 <a href='https://capacityeurope.com/' className='event-anchor'>Capacity
                                            Europe</a> event. As part of its work, Spa has been tasked with building brand awareness, establishing new media
                                        partnerships and securing journalists from a host of national and business titles to join the event.
                                    </p>
                                    <br />


                                    <p className='p-text'>

                                        The event brings together the entire connectivity and digital infrastructure ecosystem – from the submarine cable
                                        operators that bring data across continents to the NaaS and software solution providers that help to secure,
                                        automate and remotely operate increasingly complex network infrastructure.
                                    </p>
                                    <br />
                                    <p className="p-text">

                                        The 2024 event, which takes place on 15th-17th October, will see over 3,000 senior decision-makers gather at
                                        Intercontinental at the O2 to network, and gain fresh insights from global leaders including AWS, Nokia, Deutsche
                                        Telekom, Equinix and Connectbase.
                                    </p>
                                    <br />
                                    <p className="p-text">

                                        <i>  “Capacity Europe is the premier event for Europe for Connectbase. We find the ecosystem that comes together at
                                            the event second to none and an opportunity to learn, engage and work collaboratively with our partners across
                                            Europe”</i>. Ben Edmond CEO and founder of Connectbase
                                    </p>


                                    <br />
                                    <p className="p-text">

                                        Capacity Europe is a well-established global hub, with connectivity providers across the Middle East, Asia, USA,
                                        Africa and Latin America joining to renew and establish agreements with their European partners.
                                        Digital infrastructure is converging, and there is a need for better collaboration and partnerships between fibre,
                                        data centre, subsea, satellite, and software services to serve future digital economies. This means Capacity
                                        Europe has evolved from being the largest gathering of network operators to a critical touch point for all
                                        connectivity enablers.
                                    </p>


                                    <br />

                                    <p className="p-text">


                                        To officially open the event, Didem Ün Ateş, Chief Executive, Lotus AI will give an inspirational keynote talk about
                                        the potential of AI to fundamentally change the connectivity industry.
                                    </p>

                                    <br />

                                    <p className="p-text">

                                        Across the event, other speakers include Gustavo Carvalho, Chief Customer Operations & Technology Officer -
                                        TELEFÓNICA GLOBAL SOLUTIONS, Patrick George, CEO, iBASIS and Jean-Louis Le Roux, EVP International Network
                                        Infrastructure & Services - ORANGE, Jennifer Smith, CFO, Zayo Europe, and Ilona Koprowska, Network Site
                                        Investment – META.
                                    </p>

                                    <br />
                                    <p className="p-text mt-2">

                                        <i> “Capacity Europe brings together senior executives and decision-makers from across Europe and beyond,

                                            reflecting the diverse regions where RETN operates. As a business focused on connectivity, the conference is
                                            essential because it connects people, ideas, and opportunities to build the future of digital communication.”</i>
                                        Olena Lutsenko, Business Development Director at RETN
                                    </p>


                                    <br />

                                    <p className="p-text">

                                        This year the show welcomes 20% diverse speakers to the stages, with 60+ new speakers added to the agenda.
                                        This reflects the team’s commitment to give a voice to emerging talent from a wider range of geographies and
                                        represent the evolving digital infrastructure ecosystem.

                                    </p>
                                    <br />

                                    <br />

                                    <p className="p-text">

                                        Gabriela Cogorno, Head of Events Marketing at techoraco said: “Capacity Europe is set to be our most impactful
                                        year yet. With our new additions, such as the revamped floor space, <a href='https://www.capacityeurope.com/fibre-engineering-leadership-summit' className='event-anchor'>Fibre Engineering Summit</a> and  <a href='https://www.capacityeurope.com/talent-program-partners' className='event-anchor'>Talent
                                            Programme</a>, we are not only addressing the needs of the industry in the present, but also future-proofing and
                                        anticipating possible trends and innovations for the sector to improve their operations. Capacity Europe is more
                                        than an event, it is a foundation for innovation in the digital sector”.

                                    </p>

                                    <br />
                                    <p className="p-text">

                                        <i> "Capacity Europe has long been recognized as one of Europe's premier events, and we're thrilled to collaborate
                                            with techoraco to expand its reach among key influencers in industry media," </i>
                                        said Charlotte Wood, Account Director at Spa Communications. <i> "With the addition of new partners and enhanced media relations, the event is
                                            set to offer even greater value to its audience, top sponsors and speakers, creating a lasting impact both now and
                                            moving forward." </i>
                                    </p>

                                    <br />

                                    <p className="p-text mb-5">
                                        Capacity Europe takes place on the 15th-17th October 2024. To ensure you don’t miss out on its industry-leading
                                        conference line-up, <a href='https://www.capacityeurope.com/registration' className='event-anchor'>register</a> for your ticket today, or visit the website <a href='https://www.capacityeurope.com/' className='event-anchor'>here</a>.  Please reach out to the Capacity press
                                        team on  <a href="mailto:CapacityEurope@spacomms.com" className="event-anchor">CapacityEurope@spacomms.com</a>for any further information.

                                    </p>



                                </div>
                            </div>
                        </div>

                        {/* Right Section - 3 columns */}
                        <div className="col-md-3 mt-3">
                            <Tabs activeKey={activeKey} onSelect={handleTabSelect} id="tabs-example" className="tabBtn nav-link1">
                                <Tab eventKey="tab1" title="Latest">
                                    <TabContent className="marTop">
                         
                                            <div>
                                                {data?.latest?.map((post, index) => (
                                                    <div key={index} className="paddings">
                                                        <a href={`/${post.cat_slug}/${post.post_name}`}>
                                                            <h3 className="fw-bold h5 hoverHead" title={post?.post_title}>{post?.post_title}</h3>
                                                        </a>
                                                        <p style={{ fontSize: '13px' }}>
                                                            By <span className="fw-bold">{post?.post_author}</span> | {new Date(post?.post_date).toLocaleDateString()}
                                                        </p>
                                                        <p className="just-text line-clamp mt-1" style={{ fontSize: '15px' }}>
                                                            {post?.post_content}
                                                        </p>
                                                        <hr />
                                                    </div>
                                                ))}
                                            </div>
                                   
                                    </TabContent>
                                </Tab>

                                <Tab eventKey="tab2" title="Popular">
                                    <TabContent className="marTop">
                                        {loading ? <p>Loading...</p> : (
                                            <div>
                                                {data?.popular?.map((post, index) => (
                                                    <div key={index} className="paddings">
                                                        <a href={`/${post?.cat_slug}/${post.post_name}`}>
                                                            <h3 className="fw-bold h5 hoverHead" title={post.post_title}>{post.post_title}</h3>
                                                        </a>
                                                        <p style={{ fontSize: '13px' }}>
                                                            By <span className="fw-bold">{post.post_author}</span> | {new Date(post.post_date).toLocaleDateString()}
                                                        </p>
                                                        <p className="just-text line-clamp mt-1" style={{ fontSize: '15px' }}>
                                                            {post.post_content}
                                                        </p>
                                                        <hr />
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </TabContent>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
             


                {/* oldfbfdbfd---------------------? */}



            </div>









        </div>
    )
}

export default Events